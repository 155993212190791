

















































































































































































import abbreviate from 'number-abbreviate';
import {
  Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { Campaign, CancellationFee } from '@/shared/store/campaign';
import { Status, IStatus } from '@/shared/lib/campaign_status';
import Creative from '@/shared/components/common/Creative.vue';
import LoadingBackground from '@/shared/components/common/LoadingBackground.vue';
import { daysLeft } from '@/shared/lib/campaign';

@Component({
  components: { LoadingBackground, Creative },
  mixins: [validationMixin],
  validations: {
    reason: { required },
    customText: { required: requiredIf((x) => x.reason === 'custom') },
  },
})
export default class CancellationConfirmation extends Vue {
  $refs!: {
    exampleCreativeWrapper: HTMLElement,
    exampleCreativeContainer: HTMLElement,
  };

  @Inject() readonly cancelCampaign: Function;

  @Inject() readonly submitFeedback: Function;

  @Prop({ required: true }) campaign!: Campaign;

  @Prop({ default: 0 }) impressions: number;

  @Prop({ required: true }) cancellationFee: CancellationFee;

  cancellationComplete: boolean = false;

  get receipt() {
    return this.$store.getters['payment/receiptForCampaign'](this.campaign.id);
  }

  exampleCreative: string = '';

  exampleCreativeOriginalSize: { width: number, height: number } = { width: 0, height: 0 };

  status: IStatus = Status.Running;

  reason: string = '';

  customText: string = '';

  mounted() {
    this.$store.dispatch('payment/loadReceiptForCampaign', this.$route.params.id);
    this.$store.dispatch('recommendedAds/getExamples', { campaignId: this.campaign.id }).then((creativeIDs) => {
      if (creativeIDs.length) {
        this.exampleCreative = creativeIDs[Math.floor(Math.random() * creativeIDs.length)];
        this.$nextTick(() => {
          window.addEventListener('resize', this.handleResize);
          this.handleResize();
        });
      }
    });
    let durationDaysRunning = 0;
    if (this.campaign.activatedAt) {
      durationDaysRunning = Math.floor((Date.now() - this.campaign.activatedAt.getTime()) / (1000 * 60 * 60 * 24));
    }
    this.$store.dispatch('mixpanel/track', {
      properties: {
        locationSurfaced: this.$route.name,
        hasAudio: this.campaign.ugc!.audio.length > 0,
        numberUserImagesApplied: this.campaign.ugc!.images.length,
        numberStockImagesApplied: this.campaign.ugc!.memberImages.length,
        campaignStatus: this.campaign.status,
        selectedBudget: this.campaign.budget!.amount / 100,
        // remainingBudget: this.campaign.budget!.amount / 100,
        selectedDurationDays: this.campaign.durationDays,
        activatedAt: this.campaign.activatedAt,
        durationDaysRunning,
        durationDaysRemaining: (this.campaign.durationDays ? this.campaign.durationDays : 0) - durationDaysRunning,
        cancellationScreenShown: this.usePreviewVariant ? 'preview' : 'graph',
        cancellationFee: this.feeToBeCharged,
        appliedAdNetworks: this.campaign.adNetworks,
      },
      action: 'Zire.CampaignCancelStarted',
    });
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    if (this.$refs.exampleCreativeContainer && this.$refs.exampleCreativeContainer.clientWidth > 0) {
      if (this.exampleCreativeOriginalSize.width === 0) {
        this.exampleCreativeOriginalSize = {
          width: this.$refs.exampleCreativeContainer.clientWidth,
          height: this.$refs.exampleCreativeContainer.clientHeight,
        };
      }
      const ratio = this.$el.clientWidth / (this.exampleCreativeOriginalSize.width + 64);
      if (ratio < 1) {
        this.$refs.exampleCreativeWrapper.style.maxHeight = `${ratio * this.exampleCreativeOriginalSize.height}px`;
        this.$refs.exampleCreativeContainer.style.transform = `scale(${ratio})`;
      } else {
        this.$refs.exampleCreativeWrapper.style.maxHeight = '';
        this.$refs.exampleCreativeContainer.style.transform = '';
      }
    }
  }

  get isFreeCampaign() {
    return this.receipt ? !this.receipt.amount.amount : false;
  }

  get feeToBeCharged() {
    const fee = (this.cancellationFee.amount / 100).toFixed(2);
    if (this.campaignInReview || this.campaignIsRejected) {
      return 0;
    }
    if (this.hasCancellationFee) {
      return fee;
    }
    return 'remainder';
  }

  get hasCancellationFee() {
    return this.cancellationFee.amount;
  }

  get cancellationFeeStr() {
    // TODO: Format this appropriately...
    return `$${(this.cancellationFee.amount / 100).toFixed(2)}`;
  }

  get formattedImpressions() {
    return abbreviate(this.impressions, 1);
  }

  get feedbackOptions() {
    const options = [
      { text: 'I want to edit the campaign', value: 'editability' },
      { text: 'Not happy with the results', value: 'results' },
      { text: 'Something else...', value: 'custom' },
    ];
    if (!this.isFreeCampaign) {
      options.unshift({ text: 'The cost is too expensive', value: 'cost' });
    }
    return options;
  }

  get headlines() {
    if (this.cancellationComplete) {
      return [
        'We\'re sorry to see you go :(',
        'Your campaign has been cancelled.  Before you go, can you please tell us why you decided to cancel so we can improve Zire going forward:',
      ];
    }
    const areYouSure = 'Are you sure you want to cancel?';
    if (this.usePreviewVariant) {
      return [areYouSure, 'You\'ll be missing out on…'];
    }
    if (this.progressPercent < 25) {
      return ['Your campaign is just getting started.', areYouSure];
    }
    if (this.progressPercent < 75) {
      return ['Your campaign is going so well.', areYouSure];
    }
    return ['Your campaign is almost over.', areYouSure];
  }

  get releaseGenre() {
    return this.campaign.ugc!.fields.releaseGenre.toLowerCase();
  }

  get hasAudio() {
    return this.campaign.ugc!.audio.length > 0;
  }

  get campaignInReview() {
    return this.campaign.status === Status.Reviewing;
  }

  get campaignIsRejected() {
    return this.campaign.status === Status.Rejected;
  }

  get usePreviewVariant() {
    return this.campaignInReview || this.campaignIsRejected || this.impressions === 0;
  }

  get progressPercent() {
    if (this.campaign.status === Status.Running) {
      const left = daysLeft(this.campaign);
      const duration = this.campaign.durationDays!;
      return (100 * (duration - left)) / duration;
    }
    return 0;
  }

  get progressText() {
    const progressPercent = Math.round(this.progressPercent);
    if (progressPercent >= 100) {
      return 'Almost Complete';
    }
    return `${progressPercent}% Complete`;
  }

  get graphPhase() {
    if (this.progressPercent < 30) {
      return 'phase-1';
    }
    if (this.progressPercent < 70) {
      return 'phase-2';
    }
    return 'phase-3';
  }

  confirm() {
    this.cancelCampaign().then(() => {
      this.$emit('confirmed');
      this.cancellationComplete = true;
      let durationDaysRunning = 0;
      if (this.campaign.activatedAt) {
        durationDaysRunning = Math.floor((Date.now() - this.campaign.activatedAt.getTime()) / (1000 * 60 * 60 * 24));
      }
      this.$store.dispatch('mixpanel/track', {
        properties: {
          locationSurfaced: this.$route.name,
          hasAudio: this.campaign.ugc!.audio.length > 0,
          numberUserImagesApplied: this.campaign.ugc!.images.length,
          numberStockImagesApplied: this.campaign.ugc!.memberImages.length,
          campaignStatus: this.campaign.status,
          selectedBudget: this.campaign.budget!.amount / 100,
          // remainingBudget: this.campaign.budget!.amount / 100,
          selectedDurationDays: this.campaign.durationDays,
          activatedAt: this.campaign.activatedAt,
          durationDaysRunning,
          durationDaysRemaining: (this.campaign.durationDays ? this.campaign.durationDays : 0) - durationDaysRunning,
          cancellationScreenShown: this.usePreviewVariant ? 'preview' : 'graph',
          cancellationFee: this.feeToBeCharged,
          appliedAdNetworks: this.campaign.adNetworks,
        },
        action: 'Zire.CampaignCancelCompleted',
      });
      window.scrollTo(0, 0);
    });
  }

  submitFeedbackAndClose() {
    this.$v.$touch();
    this.$nextTick(() => {
      if (!this.$v.$anyError) {
        this.submitFeedback({
          campaignId: this.campaign.id,
          reason: this.reason,
          customText: this.customText,
        }).then(() => {
          let durationDaysRunning = 0;
          if (this.campaign.activatedAt) {
            durationDaysRunning = Math.floor((Date.now() - this.campaign.activatedAt.getTime()) / (1000 * 60 * 60 * 24));
          }
          const numberUserImagesApplied = this.campaign.ugc!.images.length;
          const numberStockImagesApplied = this.campaign.ugc!.memberImages.length;
          const creativeControlMixpanelData = this.$store.getters['recommendedAds/getCreativeControlMixpanelData'];
          this.$store.dispatch('mixpanel/track', {
            properties: {
              locationSurfaced: this.$route.name,
              hasAudio: this.campaign.ugc!.audio.length > 0,
              numberUserImagesApplied,
              numberStockImagesApplied,
              numberTotalImagesApplied: numberUserImagesApplied + numberStockImagesApplied,
              numberUserCopyEditsApplied: creativeControlMixpanelData.numberUserCopyEditsApplied,
              numberUserDisabledAds: creativeControlMixpanelData.numberUserDisabledAds,
              numberIndividuallyEditedAds: creativeControlMixpanelData.numberUserCopyEditsApplied + creativeControlMixpanelData.numberUserDisabledAds,
              numberEnabledLocalAds: creativeControlMixpanelData.numberEnabledLocalAds,
              numberEnabledPlayableAds: creativeControlMixpanelData.numberEnabledPlayableAds,
              numberEnabledFeedbackAds: creativeControlMixpanelData.numberEnabledFeedbackAds,
              numberEnabledStreamingAds: creativeControlMixpanelData.numberEnabledStreamingAds,
              numberEnabledFBAds: creativeControlMixpanelData.numberEnabledFBAds,
              numberEnabledIGAds: creativeControlMixpanelData.numberEnabledIGAds,
              campaignStatus: this.campaign.status,
              selectedBudget: this.campaign.budget!.amount / 100,
              // remainingBudget: this.campaign.budget!.amount / 100,
              selectedDurationDays: this.campaign.durationDays,
              activatedAt: this.campaign.activatedAt,
              durationDaysRunning,
              durationDaysRemaining: (this.campaign.durationDays ? this.campaign.durationDays : 0) - durationDaysRunning,
              cancellationScreenShown: this.usePreviewVariant ? 'preview' : 'graph',
              cancellationFee: this.feeToBeCharged,
              reasonCancelled: this.reason,
              appliedAdNetworks: this.campaign.adNetworks,
            },
            action: 'Zire.CampaignCancelFeedbackGiven',
          });
          this.$emit('close');
        });
      }
    });
  }
}
