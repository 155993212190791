





































import {
  Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

@Component
export default class FullScreenModal extends Vue {
  $refs!: {
    modal: BModal,
  };

  @Prop({ required: true }) modalId: string;

  @Inject() readonly forceHiddenNavBar: Function;

  enabled: boolean = false;

  show() {
    this.$refs.modal.show();
  }

  hide(trigger?: string) {
    this.$refs.modal.hide(trigger);
  }

  handleShow() {
    if (this.forceHiddenNavBar) {
      this.forceHiddenNavBar(true);
    }
  }

  handleHidden() {
    if (this.forceHiddenNavBar) {
      this.forceHiddenNavBar(false);
    }
  }

  lockBodyScroll() {
    if (this.$slots.default) {
      disableBodyScroll(this.$slots.default[0].elm, {
        allowTouchMove: () => true,
      });
    }
  }

  clearBodyScroll() {
    if (this.$slots.default) {
      enableBodyScroll(this.$slots.default[0].elm);
    } else {
      clearAllBodyScrollLocks();
    }
  }
}
